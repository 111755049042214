@import '../../mixins';

.news-card {
  position: relative;

  width: rem(390);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-dark);
  border-radius: var(--radius-common);

  transition: box-shadow var(--animation-timing) var(--cubic-bezier);

  overflow: hidden;

  @include mediaBigDesktop {
    width: big(390);

    border-width: big(1);
  }

  @include mediaLaptop {
    width: rem(295);
  }

  @include mediaTablet {
    width: rem(229);
  }

  @include mediaMobile {
    width: 100%;
  }

  @include hover {
    box-shadow: var(--shadow-large);

    & .news-card {
      &__image {
        & img {
          transform: scale(1.2);
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
    display: flex;
    flex-shrink: 0;

    overflow: hidden;

    & picture {
      width: 100%;

      user-select: none;
      pointer-events: none;
    }

    & img {
      width: 100%;
      height: rem(250);

      object-fit: cover;
      object-position: center;

      transition: transform var(--animation-timing-long) var(--cubic-bezier);

      user-select: none;
      pointer-events: none;
      
      @include mediaDesktop {
        height: auto;
        aspect-ratio: 550/350;
      
        @supports not (aspect-ratio:1/1) {
          height: rem(350);
        }
      }

      @include mediaBigDesktop {
        height: big(250);
      }

      @include mediaLaptop {
        height: rem(200);
      }

      @include mediaTablet {
        height: rem(160);
      }

      @include mediaMobile {
        height: rem(202);
      }
    }
  }

  &__tag-list {
    position: relative;
    z-index: 1;

    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    place-content: start;
    flex-wrap: wrap;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }
  }

  &__content {
    padding: rem(20);

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(15);

    @include mediaBigDesktop {
      padding: big(20);
      gap: big(15);
    }

    @include mediaLaptop {
      padding: rem(15);

      gap: rem(10);
    }
  }

  &__title {
    color: var(--text-dark-primary);

    text-decoration: none;

    &::before {
      @include pseudo(0)
    }
  }

  &__date {
    margin-top: auto;

    display: flex;
    align-items: center;
    justify-content: start;
    gap: rem(10);

    color: var(--text-dark-secondary);

    @include mediaBigDesktop {
      gap: big(10);
    }
  }

  &__calendar {
    width: rem(16);
    height: rem(16);

    fill: var(--text-dark-secondary);

    @include mediaBigDesktop {
      width: big(16);
      height: big(16);
    }
  }
}
